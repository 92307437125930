.Games {
  line-height: var(--button-height);
  text-align: center;
}

.loser {
  background-color: var(--background);
}

.gameLink {
  border-radius: 20px;
}

.nameGrid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 8px;
}

.nameGrid button {
  background-color: var(--background);
  border: 2px solid var(--action-color);
}

.nameGrid button.selected {
  background-color: var(--action-color);
  color: var(--background);
}

.gameGrid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 40px 3fr 2fr 3fr 40px;
}

.numberGrid {
  display: grid;
  font-size: 18px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 20px);
  line-height: 20px;
}

.d {
  color: var(--light-text);
  font-size: 15px;
  grid-column: 1 / 3;
}

.totalLeft {
  grid-column-start: 2;
  background-color: var(--background);
}

.totalRight {
  grid-column-end: -1;
}
