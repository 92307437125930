.Ratings {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.Rating {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 8px;
  grid-template-columns: 40px 1fr 1fr;
  text-align: center;
}

.Rating div {
  height: var(--button-height);
  line-height: var(--button-height);
}

.rank {
  background-color: var(--highlight);
  border-radius: 20px;
}

.label {
  background-color: var(--highlight);
}

.name {
  background-color: var(--background);
}
