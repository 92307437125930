.gamesHeader {
  height: var(--button-height);
  line-height: var(--button-height);
  text-align: center;
  margin-bottom: 8px;
}

@media screen and (min-width: 601px) {
  .Main {
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
}
