.Person {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  text-align: center;
}

.opponents {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 2fr 1fr 1fr 40px;
}

.opponents div,
.opponents a {
  height: var(--button-height);
  line-height: var(--button-height);
}

.self {
  background-color: var(--highlight);
}

.rate,
.wins {
  text-align: right;
}

.button {
  border-radius: 20px;
}
